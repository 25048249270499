import { generateSelectOption } from "../../../methods/uiUtil";
import { LoadRemarksSubmitParams } from "../load/UpdateLoadRemarks";
import { DispositionSourceScreen } from "./constants";
import { TicketData } from "./types";

export const convertTicketDocsArrayIntoObj = (list: TicketData["documents"] = []) => {
    return list.reduce((acc, curr) => {
        const { url, doc_type } = curr;
        return {
            ...acc,
            [doc_type]: url
        };
    }, {} as any);
};

export const mergeRemarksParams = (params: LoadRemarksSubmitParams) => {
    const { comment, disposition, subDisposition } = params;
    return [disposition, subDisposition, comment].join("_");
};

export const getSourceScreen = (pathName: string) => {
    if (pathName.includes("/customer-")) {
        if (pathName.includes("load")) return DispositionSourceScreen.LOAD_LIST;
        else if (pathName.includes("lorry")) return DispositionSourceScreen.LORRY_LIST;
        else "";
    } else if (pathName.includes("/mw/")) {
        if (pathName.includes("load")) return DispositionSourceScreen.TSO_LOAD;
        else if (pathName.includes("lorry")) return DispositionSourceScreen.TSO_LORRY;
        else "";
    } else if (pathName.includes("/ticket-system/")) {
        // if (pathName.includes("loads")) return DispositionSourceScreen.TAP_N_GO_LIST;else
        return "ticket-system";
    }
    return "";
};

export const getSelectionOptionsFromObjMap = <K extends {}>(obj: K) => {
    return Object.entries(obj).map(([key, value]) => generateSelectOption(key, value));
};
