import React, { useState } from "react";
// Components
import TextArea, { onChangeTextAreaType } from "@vahak/core-ui/dist/components/TextArea";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import Button from "@vahak/core-ui/dist/components/Button";
import Select, {
    OnChangeSelectHandlerType,
    SelectOnChangeType,
    SelectOption
} from "@vahak/core-ui/dist/components/Select";
import Typography from "@vahak/core-ui/dist/components/Typography";
// Constants
import { TicketSysIDs } from "./ticket-system-helper/TicketSysIDs";

// Methods
import { generateFormFieldNames } from "../../utils";
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import { ModalProps } from "@vahak/core-ui/dist/components/Modal";
import { CallingDispositions, DISPOSITION_PRICE_MISMATCH } from "./ticket-system-helper/disposition";
import Input, { InputChangeHandler } from "@vahak/core-ui/dist/components/Input";
import { stringOperations } from "@vahak/core-ui/dist/methods/string";
import { Rupee } from "@vahak/core-ui/dist/constants/symbol";

export interface RemarksSubmitParams {
    comment: string;
    disposition: string;
    subDisposition: string;
}

type RemarkType = "bid" | "live-lorries" | "tap-n-go-load";

const validTypeToShowPriceFeedback = ["bid", "live-lorries"] as RemarkType[];

export interface UpdateRemarksProps {
    onSubmit: (params: RemarksSubmitParams) => void;
    comment?: RemarksSubmitParams["comment"];
    type: RemarkType;
}

interface ModalSelectedProps extends Pick<ModalProps, "titleText" | "isOpen" | "onToggle"> {}
interface UpdateRemarksMixedProps extends UpdateRemarksProps, ModalSelectedProps {}

const UpdateRemarks = (props: UpdateRemarksMixedProps) => {
    const { isOpen, onToggle, titleText } = props as ModalSelectedProps;
    const { onSubmit, comment, type } = props as UpdateRemarksProps;
    const Dispositions = CallingDispositions[type] ?? [];
    type IDisposition = typeof Dispositions[0];

    const formFieldNames = generateFormFieldNames({
        comment: "",
        disposition: "",
        subDisposition: ""
    });

    const [formData, setFormData] = useState<{
        [formFieldNames.comment]?: string;
        [formFieldNames.disposition]?: SelectOption<IDisposition>;
        [formFieldNames.subDisposition]?: SelectOption<string>;
    }>({
        comment: comment ?? ""
    });

    const inputType =
        [
            formData?.[formFieldNames.disposition]?.value?.dispositionName,
            formData?.[formFieldNames.subDisposition]?.value
        ].includes(DISPOSITION_PRICE_MISMATCH) && validTypeToShowPriceFeedback.includes(type)
            ? "number"
            : "text";

    const trimmedCommentText =
        inputType === "text" ? formData?.comment?.trim() : Number(formData?.[formFieldNames.comment]);

    const dispositionOptions: SelectOption<IDisposition>[] = Dispositions.map((disposition) => ({
        label: disposition.dispositionName,
        value: disposition
    }));

    const subDispositionList = formData?.disposition?.value?.subDispositions.map((str) => ({
        label: str,
        value: str
    }));

    const subDispositionRequired = !!subDispositionList?.length;

    const handleSubmit = () => {
        onSubmit({
            comment: formData[formFieldNames.comment] ?? "",
            disposition: formData[formFieldNames.disposition]?.label ?? "",
            subDisposition: formData[formFieldNames.subDisposition]?.label ?? ""
        });
    };

    const handleSelectChange: OnChangeSelectHandlerType<false> = (option, actionMeta) => {
        setFormData((data) => ({
            ...data,
            [formFieldNames.subDisposition]: undefined,
            [actionMeta?.name ?? ""]: option
        }));
    };

    const handleOnChangeTextArea: onChangeTextAreaType = (e) => {
        setFormData((d) => ({ ...d, [e.target.name]: e.target.value }));
    };
    const handleOnChangeInput: InputChangeHandler = (e) => {
        if (e.target.name === formFieldNames.comment) {
            setFormData((d) => ({
                ...d,
                [e.target.name]: Number(stringOperations(e.target.value, ["removeNonNumeric"])).toString()
            }));
        }
    };

    const canContinue =
        trimmedCommentText &&
        !!formData[formFieldNames.disposition] &&
        (subDispositionRequired ? !!formData[formFieldNames.subDisposition] : true);

    return (
        <ModalOrBottomSheetWrapper isOpen={isOpen} titleText={titleText} onToggle={onToggle}>
            <Flex flexDirection="column" gap={32} style={{ minHeight: 450 }}>
                <Flex flexDirection="column" gap={10}>
                    <Typography required size="m">
                        Disposition :
                    </Typography>

                    <Select
                        id={TicketSysIDs.loadTicket.disposition}
                        name={formFieldNames.disposition}
                        option={dispositionOptions}
                        value={formData.disposition}
                        placeholder={"Disposition"}
                        onChange={handleSelectChange as SelectOnChangeType}
                        isSearchable
                    />
                </Flex>

                {subDispositionRequired ? (
                    <Flex flexDirection="column" gap={10}>
                        <Typography required size="m">
                            Sub-Disposition :
                        </Typography>
                        <Select
                            id={TicketSysIDs.loadTicket.subDisposition}
                            name={formFieldNames.subDisposition}
                            option={subDispositionList}
                            value={formData.subDisposition}
                            onChange={handleSelectChange as SelectOnChangeType}
                            placeholder={"Sub-Disposition"}
                            isSearchable
                        />
                    </Flex>
                ) : null}

                {inputType === "text" ? (
                    <TextArea
                        value={formData.comment}
                        name={formFieldNames.comment}
                        onChange={handleOnChangeTextArea}
                        placeholder="Remarks here..."
                        label={"CS Remarks"}
                        rows={3}
                        id={TicketSysIDs.loadTicket.commentBox}
                        required
                    />
                ) : (
                    <Input
                        value={formData.comment || ""}
                        name={formFieldNames.comment}
                        onChange={handleOnChangeInput}
                        id={TicketSysIDs.loadTicket.commentBox}
                        type="text"
                        placeholder="Enter suggested price"
                        prefixNode={Rupee}
                    />
                )}
                <Flex justifyContent="flex-end" style={{ marginTop: "auto" }} gap={20}>
                    <Button
                        disabled={!canContinue}
                        onClick={handleSubmit}
                        width={150}
                        id={TicketSysIDs.loadTicket.primaryBtn}
                    >
                        Update
                    </Button>
                </Flex>
            </Flex>
        </ModalOrBottomSheetWrapper>
    );
};

export default UpdateRemarks;
