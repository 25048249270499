export const TicketSysIDs = {
    common: {
        commentBox: "tkt_txtf_comment",
        primaryBtn: "tkt_btn_primary",
        secondaryBtn: "tkt_btn_secondary",
        refetchBtn: "tkt_btn_refetch",
        phoneFilter: "tkt_drpd_phoneFilter",
        statusFilter: "tkt_drpd_statusFilter",
        cityFilter: "tkt_drpd_cityFilter",
        memberFilter: "tkt_drpd_memberFilter",
        subscriptionFilter: "tkt_drpd_subscriptionFilter",
        loadStatusFilter: "tkt_drpd_loadStatusFilter",
        vasTypeFilter: "tkt_drpd_vasTypeFilter",
        vasCargoTicketType: "tkt_drpd_vasCargoTicketType",
        rcTypeFilter: "tkt_drpd_rcTypeSelection",
        dateFilter: "tkt_drpd_datePicker",
        clearFilter: "tkt_btn_clearFilter",
        lorryTypeFilter: "tkt_drpd_lorryType",
        loadIdFilter: "tkt_txtf_loadId",
        status: "tkt_txtf_status",
        accept: "tkt_btn_accept",
        tsoLoadTaggedFilter: "tkt_drpd_tsoLoadTaggedFilter",
        tsoLorryTaggedFilter: "tkt_drpd_tsoLorryTaggedFilter",
        csAssignedFilter: "tkt_drpd_csAssigned",
        verifiedLoad: "tkt_drpd_verifiedLoad",
        postPlacement: {
            status: "status",
            disposition: "disposition"
        }
    },
    callMeBack: {
        accept: "callMeBack_btn_accept",
        remarksText: "callMeBack_txt_remarks",
        statusBadge: "callMeBack_txt_status"
    },
    refund: {
        accept: "refundRequest_btn_accept"
    },
    loadTicket: {
        disposition: "loadTicket_drpd_disposition",
        subDisposition: "loadTicket_drpd_subDisposition",
        commentBox: "loadTicket_txtf_comment",
        primaryBtn: "loadTicket_btn_primary",
        editBtn: "loadTicket_btn_edit",
        nextLoadBtn: "loadTicket_btn_nextLoad",
        acceptBtn: "loadTicket_btn_accept",
        rejectBtn: "loadTicket_btn_reject",
        loadIdBadge: "loadTicket_ms_loadId",
        tapNGoContainer: "loadTicket_tapNGoContainer",
        tapNGoCard: "loadTicket_tapNGoCard",
        editReportingTime: "loadTicket_btn_editReportingTime",
        editMissingField: "loadTicket_btn_editMissingField",
        appVersionIssue: "loadTicket_btn_appVersionFix"
    },
    remarks: {
        disposition: "remarks_drpd_disposition",
        subDisposition: "remarks_drpd_subDisposition",
        commentBox: "remarks_txtf_comment",
        status: "remarks_txtf_status",
        closing: "remarks_txtf_closing",
        primaryBtn: "remarks_btn_primary"
    }
};
